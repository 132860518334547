@use "src/styles/theme" as *; $themeName: "fixtercore";
.main {
  background-color: white;
}

.col {
  margin-top: 40px;
}

.row {
  margin-bottom: 32px;
}

